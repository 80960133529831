import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
const layoutProps = {};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`The Altrement`}</strong>{`, or the `}<strong parentName="p">{`Dark Between`}</strong>{`, is a place outside of reality. Not much is known at this point about it, only that `}<a parentName="p" {...{
        "href": "/Enzo%20Quint",
        "title": "Enzo Quint"
      }}>{`Enzo Quint`}</a>{` was studying the effects of `}<a parentName="p" {...{
        "href": "/Thread",
        "title": "Thread"
      }}>{`Thread`}</a>{` severance, and eventually discovered that the energy released from severing threads could be harnessed to create `}<a parentName="p" {...{
        "href": "/Entanglement",
        "title": "Entanglement"
      }}>{`Entanglements`}</a>{`, or gateways, into the Dark.`}</p>
    <p>{``}<a parentName="p" {...{
        "href": "/Etnu",
        "title": "Etnu"
      }}>{`Etnu`}</a>{` is an entity that resides, or has resided there. The `}<a parentName="p" {...{
        "href": "/Lightless%20Eye",
        "title": "Lightless Eye"
      }}>{`Lightless Eye`}</a>{` is a group in `}<a parentName="p" {...{
        "href": "/Gris",
        "title": "Gris"
      }}>{`Gris`}</a>{` that worships the Dark Between. `}<a parentName="p" {...{
        "href": "/Rikkart%20Lauten",
        "title": "Rikkart Lauten"
      }}>{`Rikkart Lauten`}</a>{` being one of their members.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      